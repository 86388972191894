import React from "react";
import { Link } from "gatsby";
import { StaticImage  } from "gatsby-plugin-image"

import LayoutSub from "../components/layoutSub";
import SEO from "../components/seo";
import BlogCards from "../components/blogCards";

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function Therapy( props) {

const {location, pageContext} = props;

const { breadcrumb: { crumbs } } = pageContext;

const {pathname} = location;
const pagetitle="当院の施術・整体"
const description="西荻窪の整体院・ぺんぎん堂での施術の方針と特徴です。他ではだめだった肩こり・首痛・頭痛をどうやって改善するのか、その秘密は…"

const { state = {} } = location;
const pathTop = (state) ? state.pathTop : "/";

  return (
    <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop} >
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
      />

      <section>

        <h1 className=" my-2 heading-b">施術・整体の方針と特徴</h1>

        <p className="mb-8">ぺんぎん堂では、
          <span className="marker">
            普通の整体とはちょっと違う ”オステオパシー”
          </span> という方法で施術をしています。アメリカやヨーロッパでは広く普及している、実績も歴史もある方法です。
        </p>

        <h2 className="heading-b">オステオパシーは普通の整体と何が違うのか？</h2>

        <p>全身を整えます、という整体は数多くあります。  オステオパシーでは、関節や筋肉などの筋骨格はもちろんですが、それに加えて、</p>

        <ul>
          <li><span className="marker">頭蓋骨の動き</span></li>
          <li><span className="marker">自律神経</span></li>
          <li><span className="marker">リンパの流れや血流</span></li>
          <li><span className="marker">内臓を支える組織</span></li>
        </ul>


        <p>なども含めて、本当の意味での「全身」を整えます。</p>

        <p>また、<span className="marker">筋骨格についても、詳細まで検査・施術する</span>こともオステオパシーの特徴です。</p>

        <p>例えば、胸の前についている「胸骨」という骨や、肋骨一本一本の動きが悪ければ、呼吸に大きな影響を及ぼします。たんに背骨を整えるだけでは改善しない「浅い呼吸」も、オステオパシーなら整えていくことができます。</p>

        <p>
          それから、オステオパシーは、<span className="marker">身体や組織を整えるためのテクニックがたくさんある</span>ことも普通の整体とは大きく違います。
        </p>

        <p>例えば、筋肉や筋膜が硬くなってしまっている場合を考えてみましょう。</p>
        <ul className="list-decimal">
          <li>ストレッチして伸ばすとゆるむ</li>
          <li>ストレッチしても痛いだけでゆるまない</li>
        </ul>

        <div className="overflow-hidden" >
          <StaticImage
            src="../images/inflexible-body.png"
            alt="ストレッチしても緩まない筋肉"
            placeholder="blurred"
            layout="constrained"
            width={240}
            className="max-w-2/5 float-left mr-5 my-3 ml-2"
          />

          <p>
            2.のケースでは、いくら伸ばしても、もんでも、筋肉は緩みません。オステオパシーには、筋膜や筋肉を適切に近づけて縮めて、<span className="marker">ストレッチでは緩まない問題を解決するテクニックもあります</span>。</p>

          <p>どうやったら問題のある筋肉・筋膜をゆるめることができるのかを判断して、適切なテクニックを使ってアプローチすることができます。</p>

        </div>

        <p>オステオパシーは、アメリカやヨーロッパで広く普及しており、イギリスでは毎日 約30,000人がオステオパシー施術を受けています。</p>

        <div className="mb-8 ml-2.5">
        <Link to='/osteopathy/' className="text-lg underline text-c-main hover-hover:hover:text-c-acc">
            <ChevronDoubleRight className="w-4 h-4 align-middle inline  text-2lx pb-0.5" />オステオパシーについて詳しくはこちらへ
          </Link>
          </div>

        <h2 className="heading-b">「今日の身体」のための施術・整体プラン</h2>


        <p className="font-bold">「慢性の肩こりで、ひどくなってくると首の後ろが痛くなって頭痛も辛いです。」</p>

        <p>こういうお悩みをお持ちの方のケースで、原因はなんだろう？と考えると、</p>

        <ul className="list-disc">
          <li><span className="marker">背骨や骨盤の歪み</span></li>
          <li><span className="marker">筋肉や筋膜の一部がかたくなって、バランスが崩れている</span></li>
          <li><span className="marker">肩甲骨・鎖骨や肋骨の動きに制限がある</span></li>
          <li><span className="marker">骨盤の柔らかさがなくなっている</span></li>
          <li><span className="marker">自律神経のバランスが崩れている</span></li>
          <li><span className="marker">内臓疲労</span></li>
          <li><span className="marker">頭蓋の動きに問題がある</span></li>
        </ul>

        <p>など、いろいろな原因があります。</p>

        <p>複数の原因がからみあっていることも多いです。</p>

        <p>当院の施術は、まずお話を伺うことからスタートします。  そして、お身体の状態をくまなく検査して、原因を探ります。</p>

        <p><span className="marker">根本的な原因をどうやって取り除くかを考えて、施術のプランを作ります。</span>  仮に原因が同じ場所にあっても、お身体全体の状態はおひとりおひとり異なりますので、施術のプランは変わります。</p>

        <p>40代女性のクライアントさんで、首の痛みが辛いと来院された方の例だと：</p>
        <ul className="list-disc">
          <li>首の骨にそって右側の筋肉が硬い。おそらくこれが直接痛みを感じている原因</li>
          <li>骨盤もぎゅっと硬くなっている</li>
          <li>肩甲骨の間が硬くなっていて、特に上の方の背骨が硬くて動きが悪い</li>
        </ul>

        <p>この方の場合は、おそらく下からの影響で首の筋肉が硬くなっていると予測をたてて、骨盤から初めて、肩甲骨の間の背骨とその周囲の小さい筋肉を緩めました。</p>

        <p><span className="marker">肩甲骨の間の背骨の周囲には、交感神経管という自律神経にかかわる重要な組織が通っています</span>。交感神経管が刺激されていると、自律神経のバランスが崩れて、痛みを増強させる原因にもなります。</p>

        <p>この方の場合は、<span className="marker">骨盤から始めて、下から体をゆるめていくと、首の緊張も痛みもだいぶ和らぎました</span>。</p>

        <p className="mb-8">同じような痛みであっても、日によって、身体の状態は変わってきます。  施術プランは、<span className="marker">その日のあなたの身体のためのオーダーメイド」</span>です。</p>



        <h2 className="heading-b">「筋膜・内臓・リンパ、そして呼吸」</h2>


        <p className="mb-6">当院では、特に「<span className="marker">筋・筋膜</span>」「<span className="marker">内臓を支えている組織</span>」「<span className="marker">リンパの流れ</span>」そして「<span className="marker">呼吸</span>」に特に重点をおいて施術しています。</p>


        <h3 className="heading-l">筋・筋膜</h3>

        <p>筋肉や、筋肉を包む筋膜は、力を入れていなくても<span className="marker">ずっと同じ状態が長く続くと硬くなったり歪んだりして不調を引き起こします</span>。長時間同じ姿勢で座ったり、立ちっぱなしでいると、筋・筋膜が部分的に硬くなって歪みが発生し、腰がだるくなったり痛くなったりすることがあります。</p>

        <p>ぺんぎん堂では、硬くなったり歪んでしまった筋膜を、硬さや歪みが解放される方向に持っていってソフトにゆるめます。オステオパシー独特のテクニックを用いて、<span className="marker">引っ張ったりもんだりせずに筋・筋膜のかたさや歪みを調整</span>します。</p>

        <p className="mb-6">筋膜は、筋肉を包んでいるだけでなく、血管や神経の通り道でもあります。筋膜のかたさや歪みを解放すると、<span className="marker">血流も良くなりますし、自律神経の乱れにも働きかけることができます</span>。</p>


        <h3 className="heading-l">内臓を支えている組織</h3>

        <div className="overflow-hidden">
          <StaticImage
            src="../images/stomach.png"
            alt="胃"
            placeholder="blurred"
            layout="constrained"
            width={100}
            className="max-w-2/5 float-left mr-5 my-3 ml-2"
          />



          <p>内臓は、腹膜や胸膜といった膜や、筋骨格につながる靱帯や筋膜で支えられています。内臓を支えている膜などの組織の滑りが悪かったり、硬くなっていたりすると、内臓そのものの機能だけでなく、<span className="marker">筋骨格へも影響が出て、例えば腰や背中が痛くなることもあります</span>。  </p>

        </div>

        <p className="mb-6">ぺんぎん堂では、<span className="marker">内臓マニピュレーション</span>などのテクニックを用いて、内臓を支えている組織に働きかけます。</p>

        <h3 className="heading-l">リンパの流れ</h3>

        <p className="mb-6">リンパの流れが悪いと、<span className="marker">冷えやコリを引き起こし</span>ます。筋膜の歪みや緊張を解放すると、リンパの流れも良くなりますが、それでもまだ足りないときには、リンパの流れに直接働きかけます。</p>

        <h3 className="heading-l">呼吸</h3>

        <p> < span className="marker">呼吸は自己治癒力に直結</span>しています。呼吸さえ良くなれば、多少の不調は自然に回復します。どれかひとつしか施術できないとしたら、呼吸に最も影響する「胸骨（胸の前についている骨です）」の動きを解放する、というオステオパシー医師もいるほどです。</p>

        <p  className="mb-8">あなたの不調が足であっても、手首であっても、呼吸がしやすくなるようにすることを考えながら、「筋・筋膜、内臓を支える組織、リンパの流れ」を調整していきます。</p>


        <h2 className="heading-b">当院の施術・整体の３つの特徴</h2>

        <h3 className="heading-l">① ソフトな力</h3>

        <p>強い力でポキポキとやられるのは怖いので不安、というかたもご安心ください！
          当院では、撫でる・触れる・軽く手足や腰を持ち上げるなど、<span className="marker">痛みのない優しい手法</span>で施術します。</p>

        <p>ぎゅっと固くなってしまった<span className="marker">身体の司令塔である脳に、「もう安心して力を抜いて大丈夫！」というメッセージを送って、身体がほぐれていくように誘導します。</span></p>

        <p className="mb-6">気持ちよくなって眠ってしまう方も多くいらっしゃいます。</p>

        <h3 className="heading-l">② 全身をくまなく丁寧に検査</h3>

        <p>筋肉や筋膜・関節などはもちろん、<span className="marker">リンパの流れの滞りや内臓疲労がないかも含めて、全身をチェック</span>します。</p>

        <p>「前は痛かった膝も、今はすっかり痛み出ていません！」
          とおっしゃっても、検査してみるとまだまだ膝周りの筋肉の硬さが残っていることもあります。</p>

        <p className="mb-6">あなたご自身が自覚のある不調はもちろん、<span className="marker">隠れている不調や、不調の前触れもあぶりだします。</span></p>

        <h3 className="heading-l">③ あなたのお身体の状態を詳しくご説明します</h3>
        <p>施術が終わったら、あなたのお身体の状態がどんなだったかを詳しくご説明します。</p>

        <p>不調の原因がなんだったかをお話しすると、お心当たりのある出来事を思い出されることも多いです。</p>

        <ul>
          <li>「そういえば、横座りするくせがあります。」</li>
          <li>「いつも左肩にカバンをかけたのが良くなかったんですね。」</li>
          <li>「いい姿勢にしようと思って、がんばって腰をそらせてました…」</li>
        </ul>
        <p>などなど。</p>

        <p><span className="marker"> 日常生活で気を付けたほうがいいことや、取り入れていただきたいストレッチもご説明します。</span></p>

        <p>日頃から、ちょっとしたことに気をつけるだけでも、身体の状態はうんと良くなります！</p>

        <p className="mb-6">もっと具体的に施術の内容を知りたいかたは、こちらをご参照ください。肩こりや五十肩に対して、どんなふうに施術するのかを説明しています。</p>

        <BlogCards targetSlug="session" />

      </section>
    </LayoutSub>
      );
}

      export default Therapy;
